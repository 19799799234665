import React, { Component } from 'react';
import './App.css';
import Uptime from './Uptime';
import DefectDetectionStatus from './DefectDetectionStatus';
import ProcessedData from './ProcessedData';
import ProcessingData from './ProcessingData';
import ErrorRate from './ErrorRate';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      authenticated: false,
      authentication_error: false,
      token: null
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    // let's make the request
    fetch(process.env.REACT_APP_VESTAS_API_MONITORING_URL + "/admin_auth",{
      method: "POST",
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "email": this.state.email,
        "password": this.state.password
      })
    })
    .then(res => res.json())
    .then(data => {
      this.setState({
        authenticated: true,
        token: data.token
      });
    }).catch((err) => {
      this.setState({
        authenticated: false,
        authentication_error: true,
        password: ""
      });
    })
  }

  render() {
    if(this.state.authenticated){
      return (
        <div>
          <nav className="navbar navbar-light bg-light">
            <nav className="navbar-brand">
              <img src="/logoVestas.png" className="d-inline-block align-top" alt=""></img><small><i>Powered by Cornis AI</i></small>
            </nav>
            <nav className="justify-content-end">
              <span id="main_title" className="d-inline-block align-bottom"><big>Vestas AI API - Status</big></span>
            </nav>
          </nav>

          <div className="container">
            <div className="row">
              <div className="col-6">
                <ProcessedData token={this.state.token}/>
              </div>
              <div className="col-6">
                <ErrorRate token={this.state.token}/>
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <Uptime/>
                <ProcessingData token={this.state.token}/>
              </div>
              <div className="col-7">
                <DefectDetectionStatus token={this.state.token}/>
              </div>
            </div>
          </div>

        </div>
      );
    }
    else
    {
      return <div className="container">
        <div className="Login">
          <br/>
          <img src="/logoVestas.png" className="logo" alt=""/><small><i>Powered by Cornis AI</i></small>
          {this.state.authentication_error &&
            <div className="alert alert-warning">Access forbidden! Please check your email and password and try again</div>
          }
          <form onSubmit={this.handleSubmit}>
            <br/>
            <div className="container">
              <div className="row">
                <div className="col-4">
                  <div className="form-group">
                    Email: 
                    <input className="form-control" id="email"
                      autoFocus
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="form-group">
                    Password: 
                    <input className="form-control" id="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      type="password"
                    />
                  </div>
                  <button disabled={!this.validateForm()} type="submit">
                    Login
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    }
  }
}

export default App;
