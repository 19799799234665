import React, { Component } from 'react';
// Object for graphic render
import {Line} from 'react-chartjs-2';
// Undescore for easy mapping
import _ from 'lodash'

// The month namess
const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

// INitial value of data to plot
var data = {
  labels: monthNames,
  datasets: [
    {
      label: "Total number of processed images",
      backgroundColor: 'rgba(0, 66, 129, 0.2)',
      borderColor: 'rgba(0, 66, 129, 1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(255,99,132,0.4)',
      hoverBorderColor: 'rgba(255,99,132,1)',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  ]
};
// save the current year
var current_year = new Date().getFullYear().toString()
var next_year = (new Date().getFullYear()+1).toString()
var current_month = new Date().getMonth().toString()

// The component
class ProcessedData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null, // En cas d'erreur lors de la requête
      isLoaded: false, // True une fois la requête terminée
      items: [],
      current_year: current_year,
      token: this.props.token
    };
  }

  componentDidMount() {
    // Get the current year to request only data for this year
    fetch(process.env.REACT_APP_VESTAS_API_MONITORING_URL + "/image_count?from="+current_year+"-01-01&till="+next_year+"-01-01", {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        'x-api-key': this.state.token
      }
    })
    .then(res => res.json())
    .then(
      (result) => {
        if(result.length){
          // Edit the data value for all month with CNs request count 
          result.forEach((el) => {
            data.datasets[0].data[el["month"]-1] = el["count"]
          })

          // get the last month with image count
          let last_month = _.max(result, (el) => {return el["month"]})["month"]
          
          // Set no data for month after the last one
          for (let i = 0; i < data.datasets[0].data.length; i++) { 
            if (i>current_month){
              data.datasets[0].data[i] = null  
            }
            
          }
        }

        this.setState({
          isLoaded: true,
          items: data
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  }

  render() {
    // The plot line for cumulative evolution of processed images
    return (
      <div>
        <div className="row">
          <div className="col-12">
            <h2>Number of Processed Images in {this.state.current_year}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Line data={this.state.items}
              width={100}
              height={250}
              options={{
                maintainAspectRatio: false}}/>
          </div>
        </div>
      </div>
    );
  }
}

export default ProcessedData;



