import React, { Component } from 'react';
// Data table objet
import DataTable from 'react-data-table-component';
import {Doughnut} from 'react-chartjs-2';
import _ from 'lodash'

// Initialize all the possible statuses list
const statuses_list = [
    {
      humanized_status_code: "Success",
      output_status_code_str: "200",
      color: "green",
      output_status_code_range: [200,200]
    },
    {
      humanized_status_code: "Bad request",
      output_status_code_str: "400",
      color: "grey",
      output_status_code_range: [400,498]
    },
    {
      humanized_status_code: "Cancel",
      output_status_code_str: "499",
      color: "orange",
      output_status_code_range: [499,499]

    },
    {
      humanized_status_code: "Internal server error",
      output_status_code_str: "500",
      color: "red",
      output_status_code_range: [500,599]
    }
  ]

// Number of month before the current date  to define the period
// of defect detection error rate
const last_n_month = 6

class DefectDetectionStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null, // En cas d'erreur lors de la requête
      isLoaded: false, // True une fois la requête terminée
      items: [],
      token: this.props.token
    };
  }

  componentDidMount() {
    let current_date = new Date ()
    let encoded_current_date = encodeURIComponent(current_date.toISOString())

    // Get the date of the day N months before
    let from_date = new Date (current_date.setMonth(current_date.getMonth()-last_n_month))
    let encoded_from_date = encodeURIComponent(from_date.toISOString())

    fetch(process.env.REACT_APP_VESTAS_API_MONITORING_URL + "/output_status_count?type=DETECTION&from="+encoded_from_date+"&till="+encoded_current_date, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        'x-api-key': this.state.token
      }
    })
    .then(res => res.json())
    .then(
      (result) => {
        // Fitler Vestas finished requests
        result = result.filter((el)=>el.is_vestas)
        result = result.filter((el)=>el.output_status_code != null)
        
        // Compute total
        let total = result.map(el => el.count).reduce((tot,el)=>  tot+el, 0)

        // Compute percentage for each status
        statuses_list.forEach((status) => {
          status.count = result.filter((el) => el.output_status_code >= status.output_status_code_range[0] && el.output_status_code <= status.output_status_code_range[1] ).map(el => el.count).reduce((tot,el)=>  tot+el,0)
          status.percentage = (status.count/total*100).toFixed(1)+" %"
        })

        this.setState({
          isLoaded: true,
          items: statuses_list,
          labels: _.map(statuses_list, (e) => {return e.humanized_status_code}),
          datasets: [{ data: _.map(statuses_list, (e) => {return e.count}), backgroundColor: _.map(statuses_list, (e) => {return e.color})}]
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  }

  render() {
    // Les colonnes du datatable d'erreurs:
    const columns = [
      {
        name: 'Request status',
        selector: 'humanized_status_code',
        sortable: true,
      },
      {
        name: 'Status Code',
        selector: 'output_status_code_str',
        sortable: true,
      },
      {
        name: 'Count',
        selector: 'count',
        sortable: true,
      },
      {
        name: 'Rate',
        selector: 'percentage',
        sortable: true,
      }
    ];

    return (
      <div>
        <h2>Detection Processing Status - last {last_n_month} months </h2>
        <Doughnut data= {{"labels": this.state.labels, "datasets": this.state.datasets}} height="50%"/>
        <DataTable
          columns={columns}
          noHeader={true}
          data={this.state.items}
          defaultSortField="percentage"
          defaultSortAsc={false}/>
      </div>
    );
  }
}

export default DefectDetectionStatus;



