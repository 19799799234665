import React, { Component } from 'react';

// Data table objet
import DataTable from 'react-data-table-component';


class Uptime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null, // En cas d'erreur lors de la requête
      isLoaded: false, // True une fois la requête terminée
      uptimes: [1,7,30].map((days) => {
        let plural_string = ""
        if (days>1){plural_string = "s"}
        return {
        "period": "last " + days + " day"+plural_string,
        "uptime": "?"
      }})
    };

  }

  componentDidMount() {
    fetch("https://api.uptimerobot.com/v2/getMonitors", {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        "api_key": process.env.REACT_APP_UPTIME_ROBOT_API_KEY,
        "custom_uptime_ratios": "1-7-30",
        "monitors": "776911680"
      })
    })
    .then(res => res.json())
    .then(
      (result) => {
        let uptimes_pcts = result["monitors"][0]["custom_uptime_ratio"].split("-");
        let state_uptimes = this.state.uptimes;
        for(let i=0; i<state_uptimes.length; i++){
          state_uptimes[i]["uptime"] = parseFloat(uptimes_pcts[i]).toFixed(4).toString() + "%"
        }
        this.setState({
          isLoaded: true,
          uptimes: state_uptimes
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  }


  render() {
    // Les colonnes du datatable d'erreurs:
    const columns = [
      {
        name: 'Period',
        selector: 'period',
        sortable: true,
      },
      {
        name: 'Uptime',
        selector: 'uptime',
        sortable: true,
      }
    ];

    return (
      <div>
        <h2>Uptime</h2>
        <DataTable
          columns={columns}
          data={this.state.uptimes}
          noHeader={true}
        />
      </div>
    );
  }
}

export default Uptime;



