import React, { Component } from 'react';
// Undescore for easy mapping
import _ from 'lodash'
// List of request to display in the in processing part
const reftypes = [{raw_name: "DEFECT", readable: "Defect Detection"}]

// The component
class ProcessingData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null, // En cas d'erreur lors de la requête
      isLoaded: false, // True une fois la requête terminée
      items: [],
      token: this.props.token
    };
  }

  componentDidMount() {
    fetch( process.env.REACT_APP_VESTAS_API_MONITORING_URL + "/processing_info", {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        'x-api-key': this.state.token
      }
    })
    .then(res => res.json())
    .then(
      (result) => {

        // Fitler Vestas requests
        result = result.filter((el)=>el.cns_request_is_vestas)

        // Count request by type
        let data = result.reduce(function(obj, v) {
          // increment or set the property
          // `(obj[v.status] || 0)` returns the property value if defined
          // or 0 ( since `undefined` is a falsy value
          obj[v.request_type.split("-")[1]] = (obj[v.request_type.split("-")[1]] || 0) + 1;
          // return the updated object
          return obj;
          // set the initial value as an object
        }, {})

        this.setState({
          isLoaded: true,
          items: data
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  }

  // {this.state.items.map(type => (
  //       
  //     ))}

  render() { 
    let items = this.state.items
    return(
    <div>
      <br/> 
      <h2>Requests in progress</h2>
      <br/>
      <ul>
      {_.chain(reftypes).map(
          (type) => {
              if(items[type.raw_name]==null){
                return (<li key={type.raw_name}> No {type.readable} Request</li>)
              }else{
                return (<li key={type.raw_name}> {items[type.raw_name]} {type.readable} Request(s)</li> )
              }
        }).value()}          
      </ul>
    </div>
    )
  }
}

export default ProcessingData;



